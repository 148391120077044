export default {
    newCourse: {
        title: "",
        category: "",
        price: 1000,
        idioma: "",
        file: "",
        emailSupport: "",
        description: ""
    },
    myCourses: {
        metaData: {},
        data: []
    },
    course: {},
    selected: {
        type: "",
        id: ""
    },
    modules: []
}