export default {
    courses: {
        metaData: {},
        data: []
    },

    enrollments: {
        metaData: {},
        data: []
    },
    modal: {
        name: "",
        id: "",
        show: false
    },
    profile: {}
}