<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

export default {
  components: {

  },
  name: "App",
  data() {
    return {
 
    };
  },
};
</script>

<style scoped>
</style>